
import {getMedicineList} from './service'

export default {
  namespaced: true,
  state: {
    medicineItem: {},
    medicineList: [],
    currentItem: {},
    loading: false,
  },
  mutations: {
    clearStateMedicine (state) {
      state.medicineItem = {}
      state.medicineList = []
      state.currentItem = {}
      state.loading = false
    },
    showLoading (state) {
      state.loading = true
    },
    hideLoading (state) {
      state.loading = false
    },
    saveMedicine (state, payload) {
      state.medicineItem = payload.payload
    },
    updateMedicineList (state, payload) {
      state.medicineList = payload.data.list
    },
  },
  actions: {
    async searchMedicineList ({commit }, payload) {
      commit({
        type: 'showLoading'
      })
      try {
        const { data } = await getMedicineList({menu:payload.menu})
        commit({
          type: 'updateMedicineList',
          data
        })
        let list = {medicine_name: payload.item.name, medicine_id: ''}
        for (let i of data.list) {
          if(i.medicine_id === payload.item.id) {
            list = i
          }
        }
        commit({
          type: 'saveMedicine',
          payload: list
        })
      } finally {
        commit({
          type: 'hideLoading'
        })
      }
    },
    saveCurrentItem ({state,rootState},payload) {
      let taskList = rootState.task.taskList
      for (let i of taskList) {
        for(let item of i) {
          if(item.frontend_id === payload.frontend_id) {
            let newItem = JSON.parse(JSON.stringify(item))
            let currentItem = {}
            if(newItem.value) {
              newItem.value.forEach((i, key) => {
                if(key === payload.key) {
                  currentItem = i
                }
              })
            }
            let value1 = JSON.parse(JSON.stringify(currentItem))
            let value = {
              menu: newItem.question.medicineMenu,
              id: '', //手动没有id
              name: '',
              typeName: '',
              type: '',
              pow: '',
              pow_label: '',
              spec: '',
              spec_label: '',
              dosage_num: 0,
              dosage: '',  //  用药剂量单位value
              dosage_label: '',
              frequency_num: 0,
              frequency: '天',  // 频度
              frequency_label: '天',
              medicine: 0, // 用药用量
              medicine_unit: '天', // 用药用量单位
              ...value1
            }
            state.currentItem = JSON.parse(JSON.stringify(value))
            break
          }
        }
      }
    },
    // 保存药品的数据
    savequetions ({state,rootState},payload) {
      let newI = JSON.parse(JSON.stringify(state.currentItem))
      rootState.task.taskList = rootState.task.taskList.map(i => {
        i = i.map(item => {
          if(item.frontend_id === payload.frontend_id) {
            if(!item.value) {
              item.value = []
            }
            if(payload.key ==='') {
              item.value.push(newI)
              item.isaddMedicine = false
            } else {
              item.value.splice(payload.key,1,newI)
            }

          }
          return item
        })
        return i
      })
    },
  }
}
