import Vue from 'vue'
import Vuex from 'vuex'
import commonData from './common-data/module'

import task from './pages/task/module'
import medicine from './pages/questions/medicine-quetion/moudel'
import takePhoto from './pages/questions/takePhoto/moudel'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    commonData,
    task,
    medicine,
    takePhoto
  }
})
