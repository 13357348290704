import { getList, getListPc, answerQuestion, answerQuestionPc, getResultList, get_result_detail } from "./service"
import router from "../../router"
import { getSession } from "../../utils/session"
import { change_url } from "../../utils/format"
import { to_main_app } from "../../utils/imgUrl"

export default {
  namespaced: true,
  state: {
    auth: getSession(),
    taskList: [],
    loading: false,
    allData: {},
    resultData: {}, // 结果总数据
    resultList: [], // 和taskList类似，没有分页
    positionTop: 0,
    operate_begin: "",
  },
  mutations: {
    clearState(state) {
      state.taskList = []
      state.loading = false
      state.allData = {}
      state.resultData = {}
      state.resultList = []
      state.positionTop = 0
    },
    showLoading(state) {
      state.loading = true
    },
    hideLoading(state) {
      state.loading = false
    },
    updateOperateBegin(state) {
      state.operate_begin = new Date().getTime()
    },
    updateList(state, payload) {
      const { content = {}, cache_answers = [] } = payload.data
      const { app_frontend_config = "{}", questions = [] } = content
      const orderArr = JSON.parse(app_frontend_config).order || []
      //x4单项最多4个汉字 x3单项最多6个汉字 x2单项最多10个汉字
      const typeLength = [4, 6, 10, 22]
      state.taskList = orderArr
        .map((obj) => {
          obj = obj
            .map((obj2) => {
              const item = questions.find((item) => item.frontend_id === obj2)
              if (item) {
                item.question = JSON.parse(item.question)
                if (item.type === 1) {
                  if (item.question.hasNoknow) {
                    item.question.options &&
                      item.question.options.push({
                        label: "不清楚",
                        value: "不清楚",
                        visibles: item.question.hasNoknow_visibles,
                      })
                  }

                  if (item.question.options) {
                    let optionsLen = item.question.options.length
                    let maxLen = 0

                    item.question.options.map((v) => {
                      maxLen = Math.max(maxLen, v.label.length)
                    })

                    let res = []
                    let prev = 0
                    //计算显示类型
                    typeLength.map((v, i) => {
                      let k = Math.ceil(optionsLen / (4 - i))
                      if (prev && k === prev) {
                        res.pop()
                      }
                      res.push(4 - i)
                      prev = k
                    })

                    for (let k of res) {
                      if (typeLength[4 - k] >= maxLen) {
                        item.question.optionType = k
                        break
                      }
                    }
                  }
                } else if (item.type === 2) {
                  if (item.question.hasNoknow) {
                    item.question.options &&
                      item.question.options.push({
                        label: "不清楚",
                        value: "不清楚",
                        visibles: item.question.hasNoknow_visibles,
                      })
                  }
                  if (item.question.hasNoAll) {
                    const label = item.question.noAllName ? item.question.noAllName : "以上都不是"
                    item.question.options &&
                      item.question.options.push({
                        label,
                        value: label,
                        visibles: item.question.hasNoAll_visibles,
                      })
                  }

                  if (item.question.options) {
                    let optionsLen = item.question.options.length
                    let maxLen = 0

                    item.question.options.map((v) => {
                      maxLen = Math.max(maxLen, v.label.length)
                    })

                    let res = []
                    let prev = 0
                    //计算显示类型
                    typeLength.map((v, i) => {
                      let k = Math.ceil(optionsLen / (4 - i))
                      if (prev && k === prev) {
                        res.pop()
                      }
                      res.push(4 - i)
                      prev = k
                    })

                    console.log(res)
                    for (let k of res) {
                      if (typeLength[4 - k] >= maxLen) {
                        item.question.optionType = k
                        break
                      }
                    }
                  }
                } else if (item.type === 5) {
                  item.question.html = item.question.html.replace(/(?=[^>])[^<>]*(?=[<$])/g, function(match) {
                    return match.replace(/ /g, "&nbsp;").replace(/\n/g, "<br/>")
                  })
                  item.question.isRequired = false
                }
                item.error = "" // 报错信息
                const { answer = [], other_information = "" } =
                  cache_answers.find((el) => el.frontend_id === item.frontend_id) || {}
                if (other_information) {
                  item.other_information = other_information
                } else {
                  item.other_information = item.default_answer_other_information || ""
                }
                if (answer && answer.length > 0) {
                  if (item.type === 1 || item.type === 2 || item.type === 8 || item.type === 11 || item.type === 15) {
                    // 单选和多选是数组
                    item.value = answer
                  } else {
                    if (item.type === 10) {
                      item.value = +answer[0]
                    } else {
                      item.value = answer[0]
                    }
                  }
                } else {
                  // 统一默认值
                  item.common_default_answer = null
                  if (item.question.frontend_default_answer && item.question.frontend_default_answer.length > 0) {
                    item.common_default_answer = item.question.frontend_default_answer
                  }
                  if (item.default_answer && item.default_answer.length > 0) {
                    item.common_default_answer = item.default_answer
                  }
                  if (item.label_default_answer && item.label_default_answer.length > 0) {
                    item.common_default_answer = item.label_default_answer
                  }
                  if (item.share_gauge_default_answer && item.share_gauge_default_answer.length > 0) {
                    item.common_default_answer = item.share_gauge_default_answer
                  }
                  // 是否显示ai 1化验 2检验除外
                  if (item.common_default_answer && item.common_default_answer[0]) {
                    item.isShowAi = true
                  } else {
                    item.isShowAi = false
                  }
                  if (item.type === 1 || item.type === 2 || item.type === 8 || item.type === 11 || item.type === 15) {
                    // 单选和多选是数组
                    if (item.common_default_answer && item.common_default_answer.length > 0) {
                      item.value = item.common_default_answer
                    } else {
                      item.value = null
                    }
                  } else {
                    item.value = (item.common_default_answer && item.common_default_answer[0]) || null // 可能为数组，可能为字符串
                  }
                }
                if (item.type === 8 && item.value && item.value.length > 0) {
                  // 药品是json字符串
                  item.value = item.value.map((i) => {
                    i = JSON.parse(i)
                    return i
                  })
                  if (item.type === 14) {
                    if (item.other_information) {
                      let other_information = JSON.parse(item.other_information)
                      for (let i of other_information) {
                        if (i.selected === 1) {
                          item.value = i
                        }
                      }
                    } else {
                      let value = item.value
                      item.value = {
                        tables: value,
                      }
                    }
                  }
                }
                // 设置量表题默认值为起始分数
                if (item.type === 9 && item.value === null) {
                  item.value = item.question.options[0]
                }

                if (item.type === 8) {
                  item.isaddMedicine = false
                }
                if (item.type === 10) {
                  // 若为步进器题，初始值为起始分数
                  if (!item.value) {
                    item.value = item.question.options[0]
                  } else {
                    item.value = +item.value
                  }
                }
                return item
              } else {
                return null
              }
            })
            .filter((obj) => obj)
          return obj
        })
        .filter((obj) => obj && obj.length > 0)
      state.allData = payload.data
    },
    updateResults(state, payload) {
      const { answers = [], content = {} } = payload.data
      const { questions = [] } = content
      let showArr = []
      let resultList = questions
        .map((item) => {
          item.question = JSON.parse(item.question)
          if (item.type === 1) {
            if (item.question.hasNoknow) {
              item.question.options &&
                item.question.options.push({
                  label: "不清楚",
                  value: "不清楚",
                  visibles: item.question.hasNoknow_visibles,
                })
            }
          } else if (item.type === 2) {
            if (item.question.hasNoknow) {
              item.question.options &&
                item.question.options.push({
                  label: "不清楚",
                  value: "不清楚",
                  visibles: item.question.hasNoknow_visibles,
                })
            }
            if (item.question.hasNoAll) {
              const label = item.question.noAllName ? item.question.noAllName : "以上都不是"
              item.question.options &&
                item.question.options.push({
                  label: label,
                  value: label,
                  visibles: item.question.hasNoAll_visibles,
                })
            }
          } else if (item.type === 5) {
            item.question.html = item.question.html.replace(/(?=[^>])[^<>]*(?=[<$])/g, function(match) {
              return match.replace(/ /g, "&nbsp;").replace(/\n/g, "<br/>")
            })
            item.question.isRequired = false
          }
          item.error = "" // 报错信息
          const { answer = [], other_information = "" } =
            answers.find((obj) => obj.frontend_id === item.frontend_id) || {}
          if (other_information) {
            item.other_information = other_information
          } else {
            item.other_information = item.default_answer_other_information || ""
          }
          if (answer && answer.length > 0) {
            if (item.type === 1 || item.type === 2 || item.type === 8 || item.type === 11 || item.type === 15) {
              // 单选和多选是数组
              item.value = answer
            } else {
              item.value = answer[0]
            }
          } else {
            item.value = null
          }

          if (item.type === 8 && item.value && item.value.length > 0) {
            // 药品是json字符串
            item.value = item.value.map((i) => {
              i = JSON.parse(i)
              return i
            })
          }
          if (item.question.isVisible || showArr.find((index) => index === item.frontend_id)) {
            // 父元素隐藏的不显示
            if ((item.type === 1 || item.type === 2) && item.value && item.value.length > 0) {
              for (let value of item.value) {
                if (item.question.options && item.question.options.length > 0) {
                  for (let item of item.question.options) {
                    if (item.value === value) {
                      if (item.visibles) showArr = showArr.concat(item.visibles)
                      break
                    }
                  }
                }
              }
            }
            if (item.type === 4 || item.type === 3) {
              if (item.question.valuable && item.value) {
                showArr = showArr.concat(item.question.valuable)
              }
              if (item.question.noValue && !item.value) {
                showArr = showArr.concat(item.question.noValue)
              }
            }
          }
          return item
        })
        .filter((obj2) => obj2.question.isVisible || showArr.find((index) => index === obj2.frontend_id))

      // 后端的坑，数据乱存
      const { app_frontend_config = "{}" } = content
      const orderArr = JSON.parse(app_frontend_config).order || []
      let all_order = []
      orderArr.forEach((arr) => {
        all_order = all_order.concat(arr)
      })
      resultList = resultList.filter((item) => all_order.includes(item.frontend_id))

      state.resultList = resultList
      state.resultData = payload.data
      state.allData = payload.data
    },
    savePosition(state, payload) {
      state.positionTop = payload
    },
  },
  actions: {
    async getListData({ commit, rootState }, payload) {
      commit({
        type: "showLoading",
      })
      try {
        let data
        const { source } = rootState.commonData
        if (source === "app") {
          data = await getList(payload)
        } else if (source === "h5") {
          data = await getListPc(payload)
        }
        commit({
          type: "updateList",
          data: data.data,
        })
      } finally {
        commit({
          type: "hideLoading",
        })
      }
    },
    async submitData({ commit, rootState }, payload) {
      commit({
        type: "showLoading",
      })
      try {
        const { source } = rootState.commonData
        const { id, result, patient_id, msg_id } = payload
        if (source === "app") {
          const { data } = await answerQuestion({
            result,
            patient_seq_no: patient_id,
            gauge_id: id,
            msg_id,
          })
          const { forward_type } = data
          if (forward_type === 2) {
            let hander_data = change_url(data)
            to_main_app(`${hander_data.actions[0].url}`)
          } else {
            router.push({
              path: "/gauge-result",
              query: {
                id: data.id,
              },
            })
          }
        } else if (source === "h5") {
          const { data } = await answerQuestionPc({
            result,
            gauge_id: id,
          })
          commit({
            type: "updateResults",
            data,
          })
          router.push({
            path: "/gauge-result",
          })
        }
      } finally {
        commit({
          type: "hideLoading",
        })
      }
    },
    async searchResultList({ commit, rootState }, payload) {
      commit({
        type: "showLoading",
      })
      try {
        const { source } = rootState.commonData
        const { id } = payload
        if (source === "app") {
          const { data } = await getResultList({
            id,
          })
          let hander_data = change_url(data)
          commit({
            type: "updateResults",
            data: hander_data,
          })
        } else if (source === "h5") {
          console.log("暂无此功能！")
        }
      } finally {
        commit({
          type: "hideLoading",
        })
      }
    },
    // 获取结果详情
    async get_result_detail({ commit }, payload) {
      commit({
        type: "showLoading",
      })
      try {
        const { data } = await get_result_detail(payload)
        let hander_data = change_url(data)
        commit({
          type: "updateResults",
          data: hander_data,
        })
      } finally {
        commit({
          type: "hideLoading",
        })
      }
    },
  },
}
