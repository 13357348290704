// 数据格式化
import format from 'date-fns/format'
import qs from 'qs'


// 完整时间：yyyy-MM-dd HH:mm:ss
export function formatDateTime (timestamp) {
  return timestamp && format(+timestamp, 'yyyy-MM-dd HH:mm:ss')
}

export function formatDateMinute (timestamp) {
  return timestamp && format(+timestamp, 'yyyy-MM-dd HH:mm')
}

export function formatDate (timestamp) {
  return timestamp && format(+timestamp, 'yyyy-MM-dd')
}

export function timeDown (end) {
  // 当前时间戳
  const now = Date.parse(new Date())
  // 相差的毫秒数
  // console.log('tome',end)
  const msec = +end - now
  // console.log('tome',msec)
  // let day = parseInt(msec / 1000 / 60 / 60 / 24)
  let hr = parseInt(msec / 1000 / 60 / 60 % 24)
  let min = parseInt(msec / 1000 / 60 % 60)
  let sec = parseInt(msec / 1000 % 60)
  // 个位数前补零
  hr = hr > 9 ? hr : '0' + hr
  min = min > 9 ? min : '0' + min
  sec = sec > 9 ? sec : '0' + sec
  return {
    time: `${hr}小时${min}分${sec}秒`,
    remaining: msec
  }
}

// 图片转base64
export async function getImgBase64(url){
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.crossOrigin = 'Anonymous' //使用跨域图像
    img.onload = () => {
      const canvas = document.createElement("canvas")
      canvas.width = img.width
      canvas.height = img.height
      const ctx = canvas.getContext("2d")
      ctx.drawImage(img, 0, 0, img.width, img.height)
      const dataURL = canvas.toDataURL("image/png")
      resolve(dataURL)
    }
    img.onerror = (error) => {
      reject(error)
    }
    img.src = url
  })

}
export function combinationData (arr) {
  let map = {},
    dest = [];
  for(let i = 0; i < arr.length; i++){
    let ai = arr[i];
    // if(!ai.serve_order_id) {
    //   ai.serve_order_id = 'frontid' + i
    // }
    if(!map[ai.trigger]){
      dest.push({
        ...ai,
        data: ai.task_index ? [ai] : []
      });
      map[ai.trigger] = ai;
    }else{
      for(let j = 0; j < dest.length; j++){
        let dj = dest[j];
        if(((dj.trigger === ai.trigger)&&dj.task_index)){
          dj.data.push(ai);
          break;
        }
      }
    }
  }
  return dest

}

// 随时生成id
export function createRandomId() {
  return (Math.random()*10000000).toString(16).substr(0,4)+'-'+(new Date()).getTime()+'-'+Math.random().toString().substr(2,5)
}

export function handerType(type) {
  let text = null
  switch(type) {
  case 'chengyirenjia':
    text = 1
    break
  case 'shuomao':
    text = 2
    break
  default:
    text = null
    break

  }
  return text
}
export function get_source(val) {
  let source = null
  switch(val) {
  case 'shuomao':
    source = 4
    break
  case 'chengyirenjia':
    source = 3
    break
  default:
    source = null
  }
  return source
}

// url参数
export function get_url_params (url = location.href) {
  let data = {}
  if (url.includes('?')) {
    let query = url.split('?')[1]
    let vars = query.split('&')
    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split('=')
      data[pair[0]] = pair[1]
    }
  }
  return data
}
// 参数转化
export function change_url(payload) {
  let data = JSON.parse(JSON.stringify(payload))
  for(let action of data.actions) {
    let params = {
      action_type: action.scene,
      action_id: action.select_id,
      ...action.extend,
    }
    for (const i in params) {
      if (params[i] === undefined ||
        params[i] === null ||
        params[i] === '') {
        delete params[i]
      }
    }
    delete params.route
    delete params.name
    if(action.scene === 'health_warn') {
      params.action_result_id = payload.id
      params.history = false
      let i_params = {...params}
      action.url = '/online-chat?' + qs.stringify(i_params, {arrayFormat: 'brackets'})
    } else if(action.scene === 'online_chat')  {
      let i_params = {}
      i_params.id = params.action_id
      action.url = '/online-inquiry-detail?' + qs.stringify(i_params, {arrayFormat: 'brackets'})
    } else {
      let i_params = {}
      i_params.id = params.action_id
      action.url =  '/article?' + qs.stringify(i_params, {arrayFormat: 'brackets'})
    }
  }
  return data
}
