/* eslint-disable */

// 图片地址
export function getImgURL(url, format = false) {
  if (typeof url === "object") {
    return (url = URL.createObjectURL(url))
  }
  if (!/^http/g.test(url) && !/^data:image/g.test(url)) {
    return (url = `${GLOBAL_CONFIG.backend_server}/api/v1/operation/preview/gauges/images?file=${url}`)
  }
  return format
    ? url.replace(/_\d+x\d+\.[a-zA-Z]+$/, (val) => {
        return "." + val.split(".")[1]
      })
    : url
}

// 跳转主服务
export function to_main_app(url) {
  if (!/^http/g.test(url)) {
    url = `${location.href.split("#")[0]}#${url}`
  }
  location.href = url
}
