import api from "../../utils/api"

// 获取筛选项
export async function getList(payload) {
  return api.get(`/api/v1/mp/gauges/${payload.id}`, { params: { msg_id: payload.msg_id } })
}

// PC端获取筛选项
export async function getListPc(payload) {
  return api.get(`/api/v1/operation/preview/gauges/${payload.id}`, {
    params: { patient_seq_no: payload.patient_seq_no },
  })
}

// 提交
export async function answerQuestion(payload) {
  return api.post("/api/v1/mp/gauges/answers", payload)
}

// PC提交
export async function answerQuestionPc(payload) {
  return api.post("/api/v1/operation/preview/gauges/answers", payload)
}

// 获取图片url
export async function getImgUrl(payload) {
  return api.post("/api/v1/operation/preview/gauges/images", payload)
}

// 上传图片
export const uploadImg = (params) =>
  api.post(`/api/v1/operation/system/upload/image`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })

// 获取结果页
export async function getResultList(payload) {
  return api.get(`/api/v1/mp/gauges/answers/${payload.id}`)
}

// 执行标签
export async function handle_tag(payload) {
  return api.post(`/api/v1/mp/gauges/answers/${payload.id}/tag`, payload)
}

//已读
export async function articles_read(payload) {
  return api.post(`/api/v1/mp/articles/read`, payload)
}

// 获取结果详情
export async function get_result_detail(payload) {
  return api.get(`/api/v1/operation/preview/gauges/answers/${payload}/detail`).then(({ data }) => {
    return {
      data: {
        ...data.answer,
        answers: data.answers,
        content: data.gauge,
      },
    }
  })
}
// 获取患者信息
export async function get_patient_info(payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         patient_name: '张三',
  //       }
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v1/mp/articles/to/patients`, { params: payload })
}
