<template>
  <van-overlay :show="loading" class="loading">
    <div class="wrapper" @click.stop>
      <div class="block">
          <van-loading size="60" color="#fff"/>
      </div>
    </div>
  </van-overlay>
</template>
<script>
export default {
  name: 'GLoading',
  props: {
    loading: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
    }
  },
}
</script>
<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.block {
  width: 100px;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<style>
.loading.van-overlay {
  background-color: rgba(0, 0, 0, 0);
}
.van-overlay {
  z-index: 1000;
}
</style>
