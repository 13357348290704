import Vue from "vue"
import Router from "vue-router"
import { get_url_params } from "./utils/format"

const Task = () => import("@/pages/task")
const Html = () => import("@/pages/questions/html-quetion/Link")
const Result = () => import("@/pages/result")
const Confirm = () => import("@/pages/confirm")
// 药品
const Medicine = () => import("@/pages/questions/medicine-quetion/medicine")
const Selector = () => import("@/pages/questions/medicine-quetion/selector")
// 拍照预览
const Magnify = () => import("@/pages/questions/takePhoto/Magnify.vue")
// 结果详情
const ResultDetail = () => import("@/pages/result-detail")

Vue.use(Router)
let router = new Router({
  routes: [
    {
      path: "/gauge-task",
      name: "任务模板",
      component: Task,
    },
    {
      path: "/gauge-confirm",
      name: "结果确认",
      component: Confirm,
    },
    {
      path: "/gauge-result",
      name: "结果",
      component: Result,
    },
    {
      path: "/gauge-html",
      name: "富文本",
      component: Html,
    },
    {
      path: "/gauge-medicine",
      name: "药品",
      component: Medicine,
    },
    {
      path: "/gauge-selector",
      name: "选择",
      component: Selector,
    },
    {
      path: "/gauge-magnify",
      name: "拍照预览",
      component: Magnify,
    },
    {
      path: "/gauge-result-detail",
      name: "结果详情",
      component: ResultDetail,
    },
  ],
})

// 清除路由
export function router_destroy() {
  router = null
}

router.beforeEach((to, from, next) => {
  // 先判断里面是不是有 \u0026
  if (to.fullPath.includes("%5Cu0026")) {
    const queryStr = to.fullPath.replaceAll("%5Cu0026", "%26").replaceAll("\u0026", "%26")
    next({
      path: to.path,
      query: get_url_params(decodeURIComponent(queryStr)),
    })
    return
  }
  next()
})

export default router
