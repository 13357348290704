export default {
  namespaced: true,
  state: {
    currentItem: {},
    currentImgIndex: 0
  },
  mutations: {
    clearState (state) {
      state.currentItem = {}
    },
    setCurrentItem (state, payload) {
      state.currentItem = payload
    },
    delectImg (state, payload) {
      state.currentItem.value.splice(payload, 1)
      if (!state.currentItem.value || state.currentItem.value.length === 0) {
        history.go(-1)
      }
    },
    setCurrentImgIndex (state, payload) {
      state.currentImgIndex = payload
    },
  },
  actions: {

  }
}
