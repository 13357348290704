import { isWechat, isWebdebugger } from './validate'

// 定位
export async function geolocation () {
  return new Promise((resolve) => {
    if (isWechat && !isWebdebugger) {
      AMap.plugin('AMap.Geolocation', function() {
        let geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: false,
          // 设置定位超时时间，默认：无穷大
          timeout: 3000,
        })

        geolocation.getCurrentPosition()
        AMap.event.addListener(geolocation, 'complete', complete)
        AMap.event.addListener(geolocation, 'error', error)

        function complete (data) {
          resolve({
            lat: data.position.lat,
            lng: data.position.lng
          })
        }
        function error () {
          resolve({
            lat: null,
            lng: null
          })
        }
      })
    } else { // 网页获取gps慢，模拟数据
      resolve({
        lat: 30.55733,
        lng: 104.05647
      })
    }
  })
}
