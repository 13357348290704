import Vue from "vue"
import Vant from "vant"
import "vant/lib/index.css"
import "./reset.css"
import "swiper/dist/css/swiper.css"
import Loading from "./components/Loading.vue"

import App from "./App"

import router, { router_destroy } from "./router"
import store from "./store"
import { get_url_params } from "./utils/format"
import { setSession } from "./utils/session"

// 保存source
const href = location.href
let url_params =
  href.includes("%5Cu0026") || href.includes("\u0026")
    ? get_url_params(decodeURIComponent(href.replaceAll("%5Cu0026", "%26").replaceAll("\u0026", "%26")))
    : get_url_params()
if (url_params.source) {
  store.commit("commonData/update_source", url_params.source)
}

// 在非qiankun环境下
if (url_params.g_token && !window.__POWERED_BY_QIANKUN__) {
  setSession({
    token: url_params.g_token,
  })
}

Vue.config.productionTip = false

Vue.use(Vant)
// 全局注册loading
Vue.component(Loading.name, Loading)

let instance = null
function render(props = {}) {
  const { container } = props
  instance = new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount(container ? container.querySelector("#app") : "#app")
}

// 获取定位
store.dispatch("commonData/get_geolocation")

// bootstrap引导函数
export async function bootstrap() {
  console.log("[vue] vue app bootstraped")
}

// 挂载函数
export async function mount(props) {
  console.log("[vue] props from main framework", props)
  render(props)
}

// 卸载函数
export async function unmount() {
  instance.$destroy()
  instance.$el.innerHTML = ""
  instance = null
  router_destroy()
}

// 仅使用 loadMicroApp 方式加载微应用时生效
export async function update(props) {
  console.log("update props", props)
}

// 在qiankun环境下，修正加载路径
if (window.__POWERED_BY_QIANKUN__) {
  // eslint-disable-next-line no-undef
  __webpack_public_path__ = window.__INJECTED_PUBLIC_PATH_BY_QIANKUN__
}

// 在非qiankun环境下，直接执行渲染
if (!window.__POWERED_BY_QIANKUN__) {
  render()
}
