import { geolocation } from '../utils/geolocation'
export default {
  namespaced: true,
  state: {
    source: 'app', // 来源，app、h5
    geolocation: null, // 地理信息
    geo_timer: null, // 定时器
    start_time: null,
  },
  mutations: {
    update_source (state, payload) {
      state.source = payload
    },
    update_geolocation (state, payload) {
      state.geolocation = payload.data
    },
    update_geo_timer (state) {
      if (state.geo_timer) clearTimeout(state.geo_timer)
      state.geo_timer = setTimeout(() => {
        state.geolocation = null
      }, 300000) // 5分钟后更新
    },
    update_start_time (state) {
      state.start_time = new Date().getTime()
    },
  },
  actions: {
    async get_geolocation ({ commit, state }) {
      if (state.geolocation) {
        commit({
          type: 'update_geo_timer'
        })
        return
      }
      const data = await geolocation()
      commit({
        type: 'update_geolocation',
        data
      })
      commit({
        type: 'update_geo_timer'
      })
    },
  }
}
